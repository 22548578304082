import { graphql } from 'gatsby';
import React, {useContext, useEffect, useState} from 'react';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { RecurlyProvider, Elements } from '@recurly/react-recurly';
import { Helmet } from 'react-helmet';
import { APP_INIT_CHECKOUT } from '../../lib/events/app/types';
import {
  CHECKOUT_READY_PAGE,
  CHECKOUT_SET_SUBSCRIPTIONS,
} from '../../lib/events/checkout/types';
import { useScript } from '../../util/useScript';

import { DispatchContext, StateContext } from '../../lib/providers';

import './scss/Checkout.scss';
import SplashScreen from '../../components/misc/SplashScreen/SplashScreen';
import { CheckoutFlow } from '../../components/checkout';
import { ViewLayout } from '../../components/layout';


export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

function Checkout() {
  const { t } = useTranslation();
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);
  // const [state, dispatch] = useReducer(EventReducer, {})
  const { checkout } = state || {};

  // useScript utility loads external JS and sets state when ready for use
  const recurlyStatus = useScript('https://js.recurly.com/v4/recurly.js');

  /**
   * Consumes recurly plans endpoint
   */
  const getSubscriptions = async () => {
    const res = await fetch(
      `${process.env.GATSBY_AMWELL_API_ENDPOINT}/checkout/plans`
    );
    return res.json();
  };

  // Waits for recurlyStatus to update and ready app
  useEffect(() => {
    const recurlyReady = recurlyStatus === "ready"
    const checkoutReady = checkout
    const subscriptionsReady = checkout && checkout.availableSubscriptions
    const conditions = [recurlyReady, checkoutReady, subscriptionsReady]
    const allReady = conditions.every(val => val)
    if (allReady) {
      dispatch({ type: CHECKOUT_READY_PAGE });
      dispatch({ type: 'ready' });
    }
  }, [recurlyStatus, checkout, dispatch]);

  // Suspends app until recurly and and checkout namespace is created
  useEffect(() => {
    // dispatch({ type: "suspend" })
    dispatch({ type: APP_INIT_CHECKOUT })
    ;(async () => {
      const subscriptions = await getSubscriptions()
      dispatch({ type: CHECKOUT_SET_SUBSCRIPTIONS, payload: { subscriptions } })
    })()
  }, [dispatch])

  return checkout && checkout.pageReady ? (
    <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{t('Amwell Private Practice Checkout')}</title>
        </Helmet>
        <RecurlyProvider publicKey={process.env.GATSBY_RECURLY_PUBLIC_KEY}>
          <ViewLayout type="checkout">
            <Elements>
              <div className="Checkout">
                <Helmet>
                  <meta charSet="utf-8" />
                  <title>{t('Amwell Private Practice Checkout')}</title>
                </Helmet>
                <h1 className="Checkout-heading">
                  <Trans>Checkout</Trans>
                </h1>
                <CheckoutFlow />
              </div>
            </Elements>
          </ViewLayout>
        </RecurlyProvider>
    </>
  ) : (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t('Amwell Private Practice Checkout')}</title>
      </Helmet>
      <SplashScreen />
    </>
  );
}

export default Checkout;
